 .About-head {
     display: flex;
     align-items: center;
     gap: 20px;
 }

 .About-section {
     margin-bottom: 50px;
     margin-top: 100px;
     height: 100%;
 }

 .About-head h1 {
     color: #141414;
     font-family: "Lato" sans-serif;
     font-size: 22px;
     font-style: normal;
     font-weight: 300;
     line-height: 170%;
     /* 37.4px */
     letter-spacing: 5.5px;
     text-transform: uppercase;
 }

 .About-head img {
     width: 50px;
 }

 .about-contant {
     margin-top: 100px;

 }

 .about-contant h1 {
     color: #141414;
     font-family: Playfair Display;
     font-size: 42px;
     font-style: normal;
     font-weight: 800;
     line-height: 170%;
     /* 81.6px */
     text-transform: capitalize;
 }

 .about-contant p {
     color: #141414;
     width: 900px;
     font-family: "Lato", sans-serif;
     font-size: 22px;
     font-style: normal;
     font-weight: 300;
     line-height: 200%;
     margin-top: 40px;
     /* 44px */
 }

 .about-contant p a {
     color: #06F;
     font-family: "Lato", sans-serif;
     font-size: 22px;
     font-style: normal;
     font-weight: 300;
     line-height: 200%;
     /* 44px */
 }




 @media (max-width: 767px) {
     .About-section {
         min-height: 100%;
     }

     .About-head {
         width: 32px;
     }

     .About-head h1 {
         color: #141414;
         font-family: Lato;
         font-size: 18px;
         font-style: normal;
         font-weight: 300;
         line-height: 170%;
         /* 30.6px */
         letter-spacing: 4.5px;
         text-transform: uppercase;
     }

     .about-contant h1 {
         color: #141414;
         font-family: Playfair Display;
         font-size: 36px;
         font-style: normal;
         font-weight: 800;
         line-height: 170%;
         /* 61.2px */
         text-transform: capitalize;
     }

     .about-contant p {
         color: #141414;
         width: 350px;
         font-family: "Lato", sans-serif;
         font-size: 22px;
         font-style: normal;
         font-weight: 300;
         line-height: 200%;
         margin-top: 40px;
         /* 44px */
     }
 }