@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&family=Playfair+Display:wght@500&display=swap');

* {
  padding: 0;
  margin: 0;
  /* font-family: 'Poppins', sans-serif; */
  scroll-behavior: smooth;
  box-sizing: border-box;
}

.main {
  margin-left: 200px;
  margin-right: 200px;
}

a {
  text-decoration: none;
}

/* Phones */
@media (max-width: 430px) {
  .main {
    margin-left: 40px;
    margin-right: 40px;
  }

    .Mobile-main {
      margin-left: 5px;
      margin-right: 5px;
  }
}