#Footer {

    margin-bottom: 100px;
    margin-top: 100px;
}

.Footer-heading h1 {
    font-family: 'Playfair Display', serif;
    color: #141414;
    font-size: 42px;
    padding-bottom: 40px;
}

.Footer-heading p {
    color: #141414;
    font-weight: 400;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 170%;
    /* 37.4px */
}

.footer-icons {
    display: flex;
    gap: 40px;
    margin-top: 40px;
}

.footer-para {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.footer-para-2 {
    display: flex;
    flex-direction: column;
}

.footer-para p {
    color: #141414;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 170%
}

.icons a img {
    width: 35px;
    height: 35px;
}

@media (max-width:767px) {
    .Footer-heading h1 {
        color: #141414;
        font-family: Playfair Display;
        font-size: 29px;
        font-style: normal;
        font-weight: 800;
        line-height: 170%;
    }

    .Footer-heading p {
        color: #141414;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 170%;
    }

    .icons a img {
        width: 40px;
        height: 40px;
    }

    .footer-icons {
        display: flex;
        gap: 20px;
        margin-top: 40px;
    }

    .footer-para-2 p {
        color: #707070;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 170%;
    }
}