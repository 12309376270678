.featured-head h1 {
    font-family: 'Lato', sans-serif;
    font-weight: lighter;
    color: #141414;
    text-transform: uppercase;
    font-size: 22;
    font-weight: 300;
}

.latest-project-container {
    margin-top: 100px;
    height: 70vh;
    /* background-color: #141414; */
    padding: 5px;
    display: flex;
    gap: 20px;
}

.latest-projects {
    margin-top: 100px;
    margin-bottom: 100px;
}

.container-1 {
    /* background-color: aqua; */
    height: 100%;
    width: 65%;
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.container-2 {
    height: 100%;
    width: 35%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.container-2 img {
    height: 100%;
    width: 100%;
}

.container-3 {

    height: 100%;
    display: flex;
    align-items: self-start;
}

.container-3 {
    position: relative;


}

.container-3 img {
    width: 100%;
    height: 100%;
    border: 4px solid Transparent;
    cursor: pointer;
    transition: 300ms ease-in-out;
    border-radius: 20px;
}

.container-3 img:hover {
    border: 4px solid #808080;
    box-shadow: 0px 0px 250px 0px rgba(0, 0, 0, 0.10);
}

.container-3:hover {
    box-shadow: 0px 0px 250px 0px rgba(0, 0, 0, 0.10);
}










.container-4 {
    height: 100%;
    display: flex;
    gap: 20px;

}

.conatiner-5 {
    height: 55%;
}

.conatiner-5 img {
    width: 100%;
    height: 100%;
    border: 4px solid transparent;
    border-radius: 20px;
    transition: 300ms ease-in-out;
    cursor: pointer;
}

.conatiner-5 img:hover {
    border: 4px solid rgba(68, 92, 102, 0.50);
    box-shadow: 0px 0px 250px 0px rgba(0, 0, 0, 0.10);
}

.conatiner-6 {
    height: 251px;
    display: flex;
    align-items: end baseline;
    cursor: pointer;
}

.conatiner-6 img {
    width: 100%;
    border-radius: 20px;
    border: 4px solid transparent;
    transition: 300ms ease-in-out;
}

.conatiner-6 img:hover {
    border: 4px solid rgba(146, 227, 169, 0.50);
    box-shadow: 0px 0px 250px 0px rgba(0, 0, 0, 0.10);
}

.container-7 {
    height: 100%;
    width: 350px;
}

.container-7 img {
    width: 100%;
    border-radius: 20px;
    border: 4px solid transparent;
    cursor: pointer;
    transition: 300ms ease-in-out;
}

.container-7 img:hover {
    border: 4px solid rgba(117, 198, 228, 0.50);
    box-shadow: 0px 0px 250px 0px rgba(0, 0, 0, 0.10);
}

.container-8 {
    width: 475px;
    display: flex;
    align-items: last baseline;
}

.container-8 img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    transition: 300ms ease-in-out;
    border: 4px solid transparent;
    cursor: pointer;

}

.container-8 img:hover {
    border: 4px solid rgba(145, 170, 239, 0.50);
    box-shadow: 0px 0px 250px 0px rgba(0, 0, 0, 0.10);
}


.mobile-latest-project {
    display: none;
}

/* Phones */
@media (max-width: 767px) {
    .latest-project-container {
        display: none;
    }

    .mobile-latest-project {
        display: block;
    }

    .mobile-latest-project {
        display: flex;
        gap: 40px;
    }

    .mobile-container-1 {
        width: 155px;
        /* background-color: #808080; */
    }

    .mobile-card {
        display: flex;
        flex-direction: column;
        gap: 8px;
        /* background-color: aliceblue; */
        width: 100%;

    }

    .mobile-card img {
        width: 100%;
    }

    .link a>i {
        margin-left: 13px;
        font-size: 12px;
        transform: rotate(-45deg);
        flex-shrink: 0;
    }

    .mobile-container-1 {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .link a {
        color: #5994ED;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
        /* 23.8px */
        text-decoration-line: underline;
    }

}

/* box-shadow: 0px 0px 250px 0px rgba(0, 0, 0, 0.10); */