.Reasons {
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: 100px;
}

.Reasons-head {
    display: flex;
    align-items: center;
    gap: 20px;
}


.Reasons-head img {
    width: 50px;
}

.Reasons-head h1 {
    color: #141414;
    font-family: Lato;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 170%;
    /* 37.4px */
    letter-spacing: 5.5px;
    text-transform: uppercase;
}

.reasons-para {
    width: 900px;
}

.reasons-para p {
    color: #141414;
    font-family: "Lato", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%
}

.reason-flex-box {
    display: flex;
    flex-direction: column;
    gap: 100px;
    /* margin-top: 100px; */
    /* background-color: purple; */
}

.reason-box-1 {
    /* background-color: blueviolet; */
    height: 40%;
    display: flex;
    align-items: flex-start;
    gap: 50px;
}

.box {
    height: 100%;
    width: 50%;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.box-icon {
    display: flex;
    gap: 20px;
}

.box-icon img {

    height: 30px;
}

.box-icon h2 {
    color: #141414;
    font-family: Playfair Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 170%;
    /* 40.8px */
    text-transform: capitalize;
}

.box-desc {
    color: #141414;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 200%;
}

.education {
    /* background-color: #141414; */
    height: 100%;
    display: flex;
    gap: 100px;
}

.cards {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.card h1 {
    color: #141414;
    font-family: Playfair Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 170%;
    /* 40.8px */
    text-transform: capitalize;
    padding-bottom: 20px;
}

.card p {
    color: #141414;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
}


.card span {
    color: #141414;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 200%;
}

.Download-btn a {
    color: #5994ED;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    /* 30.6px */
    text-decoration-line: underline;
}

.Download-btn i {
    padding-left: 10px;
    color: #5994ED;
}

.card-2 {
    margin-top: 150px;
}

.card-2 h1 {
    color: #141414;
    font-family: Lato;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 170%;
    /* 37.4px */
    letter-spacing: 5.5px;
    text-transform: uppercase;
}

.card-2-heading {
    display: flex;
    align-items: center;
    gap: 20px;
}

.card-content {
    margin-top: 50px;
}

.card-content p {
    color: #141414;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
    margin-top: 20px;
}

.card-content span {
    color: #141414;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 200%;
    margin-top: 10px;
}

.card-content h2 {
    color: #141414;
    font-family: Playfair Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 170%;
    /* 40.8px */
    text-transform: capitalize;
    padding-bottom: 35px;
}

.tabs-section span {
    color: white;
    background-color: #141414;
    color: #FFF;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    padding: 5px 15px;
    border-radius: 20px;
}

.tabs-section {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.experience-card {
    width: 50%;
}

.education-card {
    width: 50%;
}

.Reasons-head-2 {
    display: none;
}

.main-flex {

    flex-wrap: wrap;
    display: flex;
}

.education {
    margin-top: 100px;
}

.Experience-head {
    display: flex;
    align-items: center;
    gap: 20px;
}

.Experience-head h1 {
    color: #141414;
    font-family: Lato;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 170%;
    /* 37.4px */
    letter-spacing: 5.5px;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .Reasons-head {
        display: none;
    }

    .Reasons {
        margin-top: 100px;
        margin-bottom: 100px;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .Reasons-head-2 {
        display: block;
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .Reasons-head-2 h1 {
        color: #141414;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 170%;
        /* 30.6px */
        letter-spacing: 4.5px;
        text-transform: uppercase;
    }

    .reasons-para {
        width: 100%;
    }

    .main-flex {
        flex-direction: column;
        display: flex;
    }

    .education {
        display: flex;
        flex-direction: column;
    }

    .reason-box-1 {
        flex-wrap: wrap;
    }

    .reason-box-1 {
        /* background-color: blueviolet; */
        height: 100%;
        display: flex;
        align-items: flex-start;
        gap: 50px;
    }

    .box-icon h2 {
        color: #141414;
        font-family: Playfair Display;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 170%;
        /* 34px */
        text-transform: capitalize;
    }

    .box {
        width: 100%;
    }

    .box-desc p {
        color: #141414;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 200%;
    }

    .reason-flex-box {
        gap: 50px;
    }

    .education {
        margin-top: 50px;
    }

    .education-card {
        width: 100%;
    }

    .experience-card {
        width: 100%;
    }

    .cards {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    .card-2 {
        margin-top: 0px;
    }

    .Experience-head h1 {
        color: #141414;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 170%;
        /* 30.6px */
        letter-spacing: 4.5px;
        text-transform: uppercase;
    }

    .Experience-head img {
        width: 32px
    }

    .cards {
        margin-top: 2px;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    .card-2-heading h1 {
        color: #141414;
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 170%;
        /* 30.6px */
        letter-spacing: 4.5px;
        text-transform: uppercase;
    }

    .card h1 {
        color: #141414;
        font-family: Playfair Display;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 170%;
        /* 34px */
        text-transform: capitalize;
    }

    .card p {
        color: #141414;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;
    }

    .card span {
        color: #141414;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 200%;
    }

    .box-icon img {
        width: 45px;
        height: 46px;
    }

    .education {
        margin-top: 60px;
    }
}